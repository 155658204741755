import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { AXIOS_INSTANCE } from "../api/axios-instance";
// import { AxiosRequestConfig } from "axios";
import { mutate } from "swr";
import { MyUserInfo, UserDto } from "../api/model";
import useCookie from "../hooks/useCookie";
import { getGetApiUserKey, useGetApiUser } from "../api/endpoints";
import Loading from "../components/Loading";
// import React, { createContext, useContext, useEffect, useState } from "react";
// import { useNavigate, Navigate, useLocation } from "react-router-dom";
// import { useCookie } from "react-use"; // Assuming you have a hook for cookies
// import { mutate } from "swr"; // For SWR revalidation
import axios, { AxiosRequestConfig,AxiosHeaders } from "axios";
// import { Loading } from "./Loading"; // Replace with your actual loading component
// import { useGetApiUser, getGetApiUserKey } from "./api"; // Replace with your API hooks

// // Types
// interface UserDto {
//   roles?: string[];
//   [key: string]: any; // Adjust this as per your UserDto structure
// }

interface AuthContextType {
  user?: UserDto;
  access_token: string | null;
  setAccessToken: (token: string | null) => void;
  isAuthenticated: boolean;
  isAdmin: boolean;
  isLoading: boolean;
}

// Auth Context
const AuthContext = createContext<AuthContextType>(null!);

// Custom Hooks
const useAuth = () => useContext(AuthContext);

export const useAccessToken = () => {
  return useCookie({
    key: "access_token",
    defaultValue: "",
  });
};

export const useRefreshToken = () => {
  return useCookie({
    key: "refresh_token",
    defaultValue: "",
  });
};

// Auth Provider
function AuthProvider({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const [accessToken, updateAccessToken] = useAccessToken();
  const [value, setValue] = useState<AuthContextType>({
    access_token: null,
    setAccessToken: (token: string | null) => {
      updateAccessToken(token, 30);
      mutate(getGetApiUserKey());
    },
    isAuthenticated: false,
    isAdmin: false,
    isLoading: true,
  });

  const { data: user } = useGetApiUser({
    swr: {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  });

  // Axios Request Interceptor
  useEffect(() => {
    const interceptorId = AXIOS_INSTANCE.interceptors.request.use(
      (config: AxiosRequestConfig<any>) => {
        if (accessToken) {
          config = {
            ...config,
            headers: accessToken
              ? {
                  ...config.headers,
                  Authorization: `Bearer ${accessToken}`,
                }
              : config.headers,
          } as AxiosRequestConfig<any>;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
    const responseInterceptorId = AXIOS_INSTANCE.interceptors.response.use(
      (response) => {
        // Successful response
        return response;
      },
      (error) => {
        if (error.response && (error.response.status === 401 || error.response.status === 403 || error.response.status === 498)) {
          console.error('Token is invalid or expired.');

          // Handle 401 403 and 498 Unauthorized
          navigate("/", { replace: true });
        }
        // Handle other errors
        return Promise.reject(error);
      }
    );
    // Revalidate user on interceptor setup
    mutate(getGetApiUserKey());

    return () => {
      AXIOS_INSTANCE.interceptors.request.eject(interceptorId);
    };
  }, [accessToken]);

  // Update Auth Context
  useEffect(() => {
    setValue((prevValue) => ({
      ...prevValue,
      user,
      access_token: accessToken,
      isAuthenticated: !!accessToken && !!user,
      isAdmin: user?.roles?.some((role) => role.toLowerCase() === "admin") || false,
      isLoading: !user && !!accessToken,
    }));
  }, [user, accessToken]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

// RequireAuth Component
function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.isLoading && (!auth.isAuthenticated || !auth.access_token)) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (auth.isLoading) {
    return <Loading />;
  }

  return children;
}

// Export Components and Hooks
export { useAuth, RequireAuth, AuthProvider };