

export function castToDate(srcDateStr: string | undefined): Date | undefined {
  if (srcDateStr === undefined) {
    return undefined;
  }
  if (srcDateStr.endsWith("Z")) {
    return new Date(srcDateStr);
  }
  return new Date(srcDateStr + "Z");
}
export function castToUnixTimestamp(srcDateStr: string | undefined): number | undefined {
  if (srcDateStr === undefined) {
    return undefined;
  }
  if (srcDateStr.endsWith("Z")) {
    return new Date(srcDateStr).getTime();
  }
  return new Date(srcDateStr + "Z").getTime();
}
