import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { CallDto, CreateCallDto, CreateStepDto } from "../../api/model";
import {
    Button,
    Card,
    Checkbox,
    Divider,
    Drawer,
    Flex,
    Group,
    Loader,
    Modal,
    Space, Tabs,
    Text,
    TextInput,
    Title,
} from "@mantine/core";
import { DatePickerInput, DateTimePicker } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import {IconInfoCircle, IconStepInto} from "@tabler/icons";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { mutate } from "swr";
import { create } from "zustand";
import {
    getApiCallGetCallList, getApiCallGetCallType,
    getGetApiCallGetCallByIdAdminKey,
    getGetApiCallGetCallListKey,
    getGetApiCallGetCallTypeKey,
    putApiCallUpdateCall,
    putApiCallUpsertCallType,
    useGetApiCallGetCallByIdAdmin,
    useGetApiCallGetCallType,
} from "../../api/endpoints/index";
import Editor from "../../components/Editor";
import CreateStep from "../../components/forms/CreateStep";
import makeKey from "../../utils/makeKey";
import { castToDate } from "./castToDate";

type CreateStepDtoWithKey = CreateStepDto & { customKey?: string };

type NewCallStore = {
  call: CreateCallDto;
  steps: CreateStepDtoWithKey[];
  setCall: (call: CreateCallDto) => void;
  clearCall: () => void;
  setSteps: (steps: CreateStepDtoWithKey[]) => void;
  moveStepUp: (step: CreateStepDtoWithKey) => void;
  moveStepDown: (step: CreateStepDtoWithKey) => void;
  setStepField: <T extends keyof CreateStepDtoWithKey>(
    step: CreateStepDtoWithKey,
    field: T,
    value: CreateStepDtoWithKey[T]
  ) => void;
  removeStep: (step: CreateStepDtoWithKey) => void;
  addStep: (step: CreateStepDtoWithKey) => void;
};

export function compareSteps(a: CreateStepDtoWithKey, b: CreateStepDtoWithKey) {
  return a.customKey && b.customKey && a.customKey == b.customKey;
}
export const useNewCallStore = create<NewCallStore>((set) => ({
  call: {
    name: "",
    description: "",
    openingAt: new Date().toDateString(),
    closingAt: new Date().toDateString(),
    callTypeId: "",
  },
  steps: [],
  moveStepUp: (step: CreateStepDto) =>
    set((store) => ({
      ...store,
      steps: store.steps
        .sort((a, b) => a.sort! - b.sort!)
        .map((s) => {
          return {
            ...s,
            sort:
              s.sort === step.sort! - 1
                ? s.sort + 1
                : s.sort === step.sort!
                ? s.sort - 1
                : s.sort,
          } as CreateStepDtoWithKey;
        }),
    })),
  moveStepDown: (step: CreateStepDto) =>
    set((store) => ({
      ...store,
      steps: store.steps
        .sort((a, b) => a.sort! - b.sort!)
        .map((s) => {
          return {
            ...s,
            sort:
              s.sort === step.sort! + 1
                ? s.sort - 1
                : s.sort === step.sort!
                ? s.sort + 1
                : s.sort,
          } as CreateStepDtoWithKey;
        }),
    })),
  setSteps: (steps: CreateStepDto[]) =>
    set((store) => ({
      ...store,
      steps: steps
        .sort((a, b) => a.sort! - b.sort!)
        .map((s) => {
          //@ts-ignore
          s.customKey = makeKey(12);
          return s as CreateStepDtoWithKey;
        }),
    })),
  addStep: (step: CreateStepDto) =>
    set((store) => {
      //@ts-ignore
      step.customKey = makeKey(12);
      return {
        ...store,
        steps: [...store.steps, step as CreateStepDtoWithKey],
      };
    }),
  removeStep: (step: CreateStepDtoWithKey) =>
    set((store) => {
      return {
        ...store,
        steps: store.steps.filter(
          (s: CreateStepDtoWithKey) => !compareSteps(s, step)
        ),
      };
    }),
  setStepField: (step, field, value) =>
    set((store) => {
      return {
        ...store,
        steps: store.steps.map((s) => {
          if (compareSteps(s, step)) {
            s[field] = value;
          }
          return s;
        }),
      };
    }),
  setCall: (call: CreateCallDto) =>
    set((store) => {
      return {
        ...store,
        call,
      };
    }),
  clearCall: () =>
    set((store) => {
      return {
        ...store,
        call: {
          name: "",
          description: "",
          openingAt: new Date().toDateString(),
          closingAt: new Date().toDateString(),
          callTypeId: "",
        },
        steps:[]
      };
    }),
}));

const ModalInfoEdit = ({
  opened,
  setOpened,
}: {
  opened: boolean;
  setOpened: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Modal opened={opened} centered onClose={setOpened} withCloseButton={false}>
      <Text mb={10}>{t("pages.admin.popup-info")}</Text>
    </Modal>
  );
};
const ModalCallLock = ({
  opened,
  setOpened,
  setLockEnd,
}: {
  opened: boolean;
  setOpened: () => void;
  setLockEnd: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Modal opened={opened} centered onClose={setOpened} withCloseButton={false}>
      <Text mb={10}>{t("pages.admin.popup-call-look-enalble", "WARN If you enable the Call Look you could not access to Call results Call before the Look End Date. Once you save the call you could not modify anymore the Look End Date.")}</Text>
      <Space h="sm" />
                <Group grow>
                <Button onClick={() => {
          setLockEnd(); // Call setLockEnd when Confirm is clicked
          setOpened();  // Close the modal after calling setLockEnd
        }}>{t("pages.admin.confirm-call-look-modal", "Confirm")}</Button>
      <Button onClick={setOpened}>{t("pages.admin.cancel-call-look-modal", "Cancel")}</Button>
                </Group>
      
    </Modal>
  );
};
const IconInfoEdit = ({ setOpened }: { setOpened: () => void }) => {
  return (
    <Flex>
      <IconInfoCircle
        size={28}
        onClick={setOpened}
        style={{
          cursor: "pointer",
          marginLeft: 10,
          marginBottom: 5,
          color: "#228be6",
        }}
      />
    </Flex>
  );
};
function CreateCall() {
  const { callId } = useParams();
  const { data: call, isLoading } = useGetApiCallGetCallByIdAdmin({ callId });
  const [opened, setOpened] = useState(false);
  const [openedLockEndModal, setOpenedLockEndModal] = useState(false);
  const {
    steps,
    addStep,
    setSteps,
    removeStep,
    clearCall,
    setCall,
    moveStepUp,
    moveStepDown,
  } = useNewCallStore((store) => store);
  const [stepEditorOpen, { open: openStepEditor, close: closeStepEditor }] =
    useDisclosure(false);
  const [selectedStep, setSelectedStep] = useState<
    CreateStepDtoWithKey | undefined
  >();
  const { t } = useTranslation();
  const { data: callType, isLoading: ctIsLoading } = useGetApiCallGetCallType({
    callTypeId: call?.callTypeId!,
  },{
      swr:{
          revalidateOnMount: true,
          revalidateOnReconnect: true,
          revalidateIfStale: true,
          revalidateOnFocus:true
      }
  });
  const form = useForm<CreateCallDto>({
    initialValues: { ...call },
  });

  const [openingDate, setOpeningDate] = useState<Date|undefined>();
  const [closingDate, setClosingDate] = useState<Date|undefined>();
  const [lockDate, setLockDate] = useState<Date|undefined>();
  const [lockDateEnable, setLockDateEnable] = useState<boolean>(false);

  useEffect(()=>{
    clearCall();
  },[])
    
  useEffect(() => {
    clearCall();
    if (callType && callType?.steps && callType.steps.length) {
      setCall(callType);
      setSteps(callType.steps!);
    }
  }, [callId, callType]);

  useEffect(() => {
    if (call) {
      if(call?.openingAt)
        setOpeningDate(castToDate(call?.openingAt!));
      
      if(call?.closingAt)
        setClosingDate(castToDate(call?.closingAt!));
      
      if(call?.callLockEnd)
        setLockDate(castToDate(call?.callLockEnd!));

      // console.log("call",call);
      // console.log("openingDate",openingDate, call?.openingAt!);
      // console.log("closingDate",closingDate, call?.closingAt!);
      // console.log("lockDate",lockDate)
      setLockDateEnable(call?.callLockEnd ? true : false)
      // console.log("lockDateEnables",lockDateEnable)
      
      form.setValues({ ...call });
    }
  }, [call]);
  const handleSetLockEnd = () => {
    // console.log("Enabling Call Look");
    setLockDateEnable(true);
    // Add any additional logic needed here to enable the call look
  };

  const handleUnSetLockEnd = () => {
    // console.log("Disable Call Look");
    setLockDateEnable(false);
    setLockDate(undefined);

    // Add any additional logic needed here to enable the call look
  };
  const navigate = useNavigate();

  if (!call || isLoading || ctIsLoading) return <Loader></Loader>;

  return (
    <>
      <ModalInfoEdit opened={opened} setOpened={() => setOpened(false)} />
      <ModalCallLock opened={openedLockEndModal} setOpened={() => setOpenedLockEndModal(false)} setLockEnd={handleSetLockEnd} />

      <Card shadow="lg" p="lg" radius="md" withBorder>
        <form
          onSubmit={form.onSubmit((values) => {
            // console.log("Submitted values:",values);
            if(values.powerBIReportID! == "")
              values.powerBIReportID = undefined
            if(values.powerBIWorkspaceID! == "")
              values.powerBIWorkspaceID = undefined
            if(values.userPowerBIReportID! == "")
              values.userPowerBIReportID = undefined
            if(values.userPowerBIWorkspaceID! == "")
              values.userPowerBIWorkspaceID = undefined


            putApiCallUpsertCallType({
              ...values,
              steps,
              id: call.callTypeId,
            })
              .then((res: any) => {
                mutate(getGetApiCallGetCallTypeKey({ callTypeId: res.id }));

                // console.log("lockDate",lockDate, lockDate?.toISOString());
                // console.log("openingDate",call?.openingAt!, openingDate, openingDate?.toISOString(), openingDate?.toLocaleTimeString(), openingDate?.toUTCString());
                // console.log("closingDate",call?.closingAt!,closingDate, closingDate?.toISOString(), closingDate?.toLocaleTimeString(), closingDate?.toUTCString());
                // console.log("values:",values);

                return putApiCallUpdateCall({
                  ...values,
                  callTypeId: res.id,
                  openingAt: openingDate?.toISOString(),
                  closingAt: closingDate?.toISOString(),
                  callLockEnd: lockDate ? lockDate?.toISOString() : undefined,
                });
              })
              .then((res: CallDto) => {
                //console.log(res);
                mutate(getGetApiCallGetCallListKey(), getApiCallGetCallList());
                mutate(getGetApiCallGetCallByIdAdminKey({ callId: res.id! }));
                showNotification({
                  title: t("pages.admin.success"),
                  message: callId
                    ? t("pages.admin.call-updated-successfully")
                    : t("pages.admin.call-created-successfully"),
                });

                navigate("/admin", { state: "calls" });
              })
              .catch(console.log);
          })}
        ><Tabs defaultValue={"General Info"}>
            <Tabs.List>
                <Tabs.Tab value="General Info">
                    General Info
                </Tabs.Tab>
                <Tabs.Tab value={"steps"} >
                    {t("pages.admin.steps")}
                </Tabs.Tab>
                <Tabs.Tab value="PowerBI">
                    PowerBI
                </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value={"General Info"} pt={16}>
                
                    <TextInput
                        withAsterisk
                        label={t("pages.admin.name")}
                        {...form.getInputProps("name")}
                    />
                <Group grow>
                    
                    <DateTimePicker 
                        title={t("pages.admin.select-dates")}
                        placeholder={t("pages.admin.pick-dates")}
                        label={t("pages.admin.opening-date")}
                        value={openingDate}
                        onChange={(v) => setOpeningDate(new Date(v!))}
                        size={"sm"}
                        withSeconds
                        // type={"default"}
                        // locale="it"
                    />
                    <DateTimePicker
                        title={t("pages.admin.select-dates")}
                        placeholder={t("pages.admin.closing-date")}
                        label={t("pages.admin.closing-date")}
                        value={closingDate}
                        onChange={(v) => v ? setClosingDate(new Date(v!)) : setClosingDate(undefined)}
                        size={"sm"}
                        withSeconds
                        // type={"default"}
                    />
                    
                </Group>
                <Space h="sm" />
                <Group grow>
                  <Checkbox label={t("pages.admin.lock-date-enable","Enable Look End Date")}
                  checked={lockDateEnable}
                  
                  disabled= {call.callLockEnd ? true : false}
                  onChange={(v) => v.currentTarget.checked ? setOpenedLockEndModal(v.currentTarget.checked) : handleUnSetLockEnd()}
                  ></Checkbox>
                  {lockDateEnable &&
                  
                <DateTimePicker
                        title={t("pages.admin.select-dates")}
                        placeholder={t("pages.admin.lock-date","Look End Date")}
                        label={t("pages.admin.lock-date","Look End Date")}
                        value={lockDate}
                        onChange={(v) => v ? setLockDate(new Date(v!)): setLockDate(undefined)}
                        size={"sm"}
                        withSeconds
                        disabled= {call.callLockEnd ? true : false}
                        minDate={new Date()}
                        clearable
                        // type={"default"}
                    />
}
                </Group>

                <Space h="sm" />
               <Group grow>
                <Checkbox label={t("pages.admin.lock-email-enable","Enable Lock on User Emails")}
                  checked={call.isUserFilterEnable}
                  
                  onChange={(v) => {
                    // console.log(v.currentTarget.checked)
                    form.setFieldValue("isUserFilterEnable", v.currentTarget.checked)
                    call.isUserFilterEnable = v.currentTarget.checked
                  
                  } }
                  ></Checkbox>
                  {call.isUserFilterEnable && (

                  
                  <TextInput
                        label={t("pages.admin.lock-email-input","List of comma separated emails can access to the call")}
                        {...form.getInputProps("userFilterEmail")}
                    />
                  )}
               </Group>
                <Space h="sm" />
                <Text size={"sm"} mb={4}>
                    {t("pages.admin.description")}
                </Text>
                <Editor
                    content={form.getInputProps("description").value}
                    onChange={form.getInputProps("description").onChange}
                />
            </Tabs.Panel>
            <Tabs.Panel value={"steps"} pt={16}>
                

                {!steps ||
                    !Array.isArray(steps) ||
                    steps.length == 0 ?
                    (<Group w={"full"} align={"center"}>
                        <Text my={16} w={"40vw"} weight={"bold"}>{t("pages.admin.popup-info")}</Text> 
                    </Group>): null
                }
                    {steps &&
                    Array.isArray(steps) &&
                    steps.length > 0 &&
                    steps
                        .sort((a, b) => a.sort! - b.sort!)
                        .map((step) => (
                            <Flex
                                w={"full"}
                                py={8}
                                style={{ borderBottom: "1px solid #e0e0e0" }}
                                justify={"space-between"}
                                align={"center"}
                            >
                                {step.name && step.name != "" ? step.name : "step"}
                                <Group>
                                    <Button
                                        variant="light"
                                        color="blue"
                                        radius="md"
                                        size="xs"
                                        onClick={() => {
                                            moveStepUp(step);
                                        }}
                                    >
                                        ↑
                                    </Button>
                                    <Button
                                        variant="light"
                                        color="blue"
                                        radius="md"
                                        size="xs"
                                        onClick={() => {
                                            moveStepDown(step);
                                        }}
                                    >
                                        ↓
                                    </Button>
                                    <Button
                                        variant="light"
                                        color="blue"
                                        radius="md"
                                        size="xs"
                                        onClick={() => {
                                            setSelectedStep(step);
                                            openStepEditor();
                                        }}
                                    >
                                        {t("pages.admin.edit-step")}
                                    </Button>
                                    <Button
                                        variant="light"
                                        color="red"
                                        radius="md"
                                        size="xs"
                                        onClick={() => {
                                            removeStep(step);
                                        }}
                                    >
                                        {t("pages.admin.remove-step")}
                                    </Button>
                                </Group>
                            </Flex>
                        ))}

                <Space h="xl" />
                <Button
                    onClick={() =>
                        addStep({
                            name: "",
                            description: "",
                            content: "",
                            sort: steps.length,
                            stepTypeId: "",
                            expectedAnswers: "",
                        })
                    }
                >
                    {t("pages.admin.add-new-step")}
                </Button>
                <Space h="xl" />
                
            </Tabs.Panel>
            <Tabs.Panel value={"PowerBI"} pt={16}>
            <Text size={"sm"} mb={4}>
                    Admin Power BI
                  </Text><Group grow>
                  
                      <TextInput
                      
                      label={t("pages.powerbi.workspace-id")}
                      {...form.getInputProps("powerBIWorkspaceID")}
                    />
                      <TextInput
                      
                      label={t("pages.powerbi.report-id")}
                      {...form.getInputProps("powerBIReportID")}
                    />
                  </Group>
                  <br></br>
                  <Divider></Divider>
                  <br></br>
                  <Group >

                  <Text size={"sm"} mb={4}>
                    User Power BI
                  </Text></Group>
                  <Group grow>
                      <TextInput
                      
                      label={t("pages.powerbi.workspace-id")}
                      {...form.getInputProps("userPowerBIWorkspaceID")}
                      />
                      <TextInput
                      
                      label={t("pages.powerbi.report-id")}
                      {...form.getInputProps("userPowerBIReportID")}
                      />
                  </Group>
                      
            </Tabs.Panel>
        </Tabs>

            <div
                style={{
                    position: "fixed",
                    bottom: 0,
                    right: 0,
                    padding: "1rem 2rem",

                    zIndex: 10,
                }}
            >
                <Group w={"100%"} position={"right"}>
                    <Button
                        variant="filled"
                        color="red"
                        mt="lg"
                        radius="md"
                        onClick={() => {
                            navigate("/admin", { state: "calls" });
                        }}
                    >
                        {t("pages.admin.cancel")}
                    </Button>
                    <Button
                        disabled={form.values.name === ""}
                        variant="filled"
                        color="blue"
                        mt="lg"
                        radius="md"
                        type="submit"
                    >
                        {callId ? t("pages.admin.save") : t("pages.admin.create")}
                    </Button>
                </Group>
            </div>
          
        </form>
      </Card>
      <Drawer
        size={"80%"}
        opened={stepEditorOpen}
        onClose={closeStepEditor}
        position={"right"}
        overlayProps={{ opacity: 0.4, blur: 4 }}
        withCloseButton={false}
      >
        {selectedStep && (
          <>
            <CreateStep step={selectedStep} close={closeStepEditor}/>
          </>
        )}
      </Drawer>
    </>
  );
}

export default CreateCall;
