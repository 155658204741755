import {
  Button,
  Paper,
  Text,
  NavLink,
  Center,
  Loader,
  Flex,
  Divider,
  Title,
  Box,
  Accordion,
  Group,
} from "@mantine/core";
import { create, useStore } from "zustand";
import {
  getApiImpactFrameworkMiniDYI,
  getGetApiCallGetSubmissionByIDKey,
  postApiCallCloseCallSubmission,
  postApiCallUpsertCallStepResult,
  useGetApiCallGetSubmissionByID,
  useGetApiStepGetSteps,
} from "../api/endpoints";
import { Step, StepResult, SubmissionResponse } from "../api/model";
import stepsConfig from "../pages/calls/stepsConfig";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import { sidebarStore } from "./Layout";
import {
  IconArrowBack,
  IconArrowLeft,
  IconArrowRight,
  IconCheck,
  IconX,
} from "@tabler/icons";
import { openConfirmModal } from "@mantine/modals";
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { quizResultsType } from "../pages/survey/survey";
import { useTranslation } from "react-i18next";
import quizTips from "../assets/json/quiz-tips.json";
import { mutate } from "swr";
import AnswerPdf from "../pages/admin/AnswerPdf";
type subStep = {
  name: string;
};

type stepWithSubSteps = Step & {
  subSteps?: subStep[];
};

type StepperStore = {
  submissionId: string;
  steps: stepWithSubSteps[];
  currentStep: [number, number];
  setSteps: (steps: stepWithSubSteps[]) => void;
  previousStep: () => void;
  nextStep: (currentStepRef?: MutableRefObject<any>) => void;
  showClosingPage: () => void;
  setCurrentStep: (indexes: [number, number]) => void;
};

const useStepperStore = create<StepperStore>((set) => ({
  submissionId: "",
  steps: [],
  currentStep: [0, 0],
  setSteps: (steps: stepWithSubSteps[]) => set((state) => ({ steps })),
  setCurrentStep: (indexes: [number, number]) => {
    set((state) => {
      return {
        currentStep: indexes,
      };
    });
  },
  nextStep: (currentStepRef) => {
    if (
      currentStepRef &&
      currentStepRef.current &&
      currentStepRef.current.validate
    ) {
      if (!currentStepRef.current.validate()) {
        return;
      }
    }
    set((state) => {
      let nextSteps = { currentStep: state.currentStep };
      // console.log("stepType",state.steps[state.currentStep[0]]?.stepType?.name)
      if (
        state.currentStep[0] < state.steps?.length - 1 &&
        state.steps[state.currentStep[0]]?.subSteps?.length === 0
      ) {
        // console.log("if 1")
        nextSteps = {
          currentStep: [state.currentStep[0] + 1, 0],
        };
      } else if (
        state.steps[state.currentStep[0]]?.stepType?.name == 'miniDyi' && (
        // state.currentStep[0] < state.steps?.length - 1 ||
        state.currentStep[1] < 5
        )
      ) {
        // console.log("if 2 ",state.currentStep[1])
        nextSteps = {
          currentStep: [state.currentStep[0], state.currentStep[1] + 1],
        };
      } else if (
        state.steps[state.currentStep[0]]?.stepType?.name != 'miniDyi' && (
        state.currentStep[0] < state.steps?.length - 1 ||
        state.currentStep[1] <
          state.steps[state.currentStep[0]]?.subSteps?.length! - 1
        )
      ) {
        // console.log("if 3")

        nextSteps = {
          currentStep: [state.currentStep[0], state.currentStep[1] + 1],
        };
      } else if (
        state.currentStep[0] < state.steps?.length - 1 &&
        state.currentStep[1] ===
          state.steps[state.currentStep[0]]?.subSteps?.length! - 1
      ) {
        // console.log("if 4")
        nextSteps = {
          currentStep: [state.currentStep[0] + 1, 0],
        };
        //the outcome of the code condition shown above is the same that occurs starting from line 75, check and manage this case
      }
      // console.log("STATE:",state  )
      // console.log("nextSteps:",nextSteps  )
      
      return nextSteps;
    });
  },
  showClosingPage: () =>
    set((state) => ({
      currentStep: [state.steps.length, 0],
    })),
  previousStep: () =>
    set((state) => {
      let nextSteps = { currentStep: state.currentStep };
      if (state.currentStep[0] > 0 && state.currentStep[1] === 0) {
        nextSteps = {
          currentStep: [
            state.currentStep[0] - 1,
            state.steps[state.currentStep[0] - 1]?.subSteps?.length == 0
              ? 0
              : state.steps[state.currentStep[0] - 1]?.subSteps?.length! - 1,
          ],
        };
      } else if (state.currentStep[0] > 0 && state.currentStep[1] > 0) {
        nextSteps = {
          currentStep: [state.currentStep[0], state.currentStep[1] - 1],
        };
      } else if (state.currentStep[0] === 0 && state.currentStep[1] > 0) {
        nextSteps = {
          currentStep: [0, state.currentStep[1] - 1],
        };
      }
      return nextSteps;
    }),
}));

export { useStepperStore };

type Props = { submission: SubmissionResponse, callTypeId: string; results: StepResult[], readonly:boolean };

function Stepper({ submission, callTypeId, results, readonly }: Props) {
  const {
    setSteps,
    currentStep,
    previousStep,
    nextStep,
    showClosingPage,
    steps: storeSteps,
    setCurrentStep,
  } = useStepperStore();

  const [sp] = useSearchParams();
  const { submissionId } = useParams();
  const { data: sub } = useGetApiCallGetSubmissionByID({
    submissionId,
  });
  const { t } = useTranslation();
  const [lowScore, setlowScore] = useState<number | undefined>(0);
  const [highScore, setHighScore] = useState<number | undefined>(0);
  const [showClosingPageLoader, setShowClosingPageLoader] =
    useState<boolean>(false);
  const [quizRecap, setQuizRecap] = useState<Record<string, boolean>>({});
  const [quizScore, setQuizScore] = useState<number>(0);
  const { data: steps, isLoading: loadingSteps, error: errorSteps } = useGetApiStepGetSteps<Step[] | undefined>({
    callTypeId,
  });

  const setSidebarOpen = useStore(
    sidebarStore,
    (state) => state.setSidebarOpen
  );
  const getScoreLabel = (lowScore: number, highScore: number) => {
    if (highScore > 8) {
      return <>{t("components.sroi-limit")}</>;
    } else {
      return (
        <>
          {t("components.sroi")} {lowScore!.toFixed(2)} {t("components.and")}{" "}
          {highScore!.toFixed(2)}
        </>
      );
    }
  };
  const currentStepRef = useRef();
  const openConfirmationModal = () =>
    openConfirmModal({
      title: t("components.close-call-submission"),
      children: <Text size="sm"> {t("components.call-confirm-proceed")}</Text>,
      labels: {
        confirm: t("components.confirm"),
        cancel: t("components.cancel"),
      },
      onConfirm: () => confirmModalAction(),
    });

  const confirmModalAction = useCallback(async () => {
    setShowClosingPageLoader(true);
    setlowScore(0);
    setHighScore(0);
    await postApiCallCloseCallSubmission({
      callSubmissionId: submissionId,
    })
      .then((res) => {
        mutate(getGetApiCallGetSubmissionByIDKey({ submissionId }));
        return res;
      })
      .then((res) => {
        if (Array.isArray(res)) {
          let miniDyi = res.find((x) => x.stepStepTypeName == "miniDyi");
          let quiz = res.find((x) => x.stepStepTypeName == "quiz");
          const processResultsPromises: Promise<any>[] = [];
          if (miniDyi && typeof miniDyi != "undefined") {
            processResultsPromises.push(
              getApiImpactFrameworkMiniDYI({ stepResult: miniDyi.id }).then(
                (miniRes) => {
                  setlowScore(miniRes?.lowerScore!);
                  setHighScore(miniRes?.upperScore!);
                  return miniRes;
                }
              )
            );
          }
          if (quiz && typeof quiz != "undefined") {
            const content: quizResultsType = JSON.parse(
              quiz.content!
            ) as quizResultsType;
            setQuizScore(quiz.score ?? 0);
            setQuizRecap(content.quizResults.recap);
          }

          Promise.all(processResultsPromises).then(() => {
            showClosingPage();
            setShowClosingPageLoader(false);
          });
        }
      });
  }, [submissionId, sub]);

  const readOnly = !!sp.get("readonly")

  useEffect(() => {
    const stepswithSubsteps: stepWithSubSteps[] | undefined = steps?.map(
      (s) => {
        const tmp: stepWithSubSteps = { ...s, subSteps: [] };
        if (tmp?.stepType?.name === "miniDyi") {
          tmp.subSteps = [
            { name: t("components.introduction") },
            { name: t("components.budget") },
            { name: t("components.sdg") },
            { name: t("components.outcomes") },
            { name: t("components.indicators") },
            { name: t("components.custom-outcome") },
          ];
        }
        return tmp;
      }
    );
    setSteps(stepswithSubsteps!);
  }, [steps]);

  useEffect(() => {
    //console.log("lowScore: ", lowScore);
    //console.log("highScore: ", highScore);
  }, [lowScore, highScore]);

  useEffect(() => {
    if (sub && sub.closed) {
      if (steps) {
        setCurrentStep([steps.length, 0]);
        if(!!sp.get("readonly")){
          setCurrentStep([0, 0]);
        }
      }
      confirmModalAction();
      //console.log("called");
    } else {
      setCurrentStep([0, 0]);
    }
  }, []);

  if (!steps) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (showClosingPageLoader && !sub?.closed) {
    return (
      <Center h={"60vh"} w={"100%"}>
        <Flex direction={"column"} justify={"center"} align={"center"}>
          <Loader my={30} />
          <Title order={2} align={"center"}>
            {t("components.processing-submission").split(",").shift()}, <br />{" "}
            {t("components.processing-submission").split(",").pop()}
          </Title>
        </Flex>
      </Center>
    );
  }
  
  if(loadingSteps) {
      return (
        <Center h={"60vh"} w={"100%"}>
        <Flex direction={"column"} justify={"center"} align={"center"}>
          <Loader my={30}/>
        </Flex>
      </Center>
    );
  }
  
  return (
    <Flex direction="column" w="100%">
      <Paper p="lg" shadow="md" mb="lg" w="100%">
        {(() => {
          if (!sp.get("readonly") && (
            (sub && sub.closed ) ||
            (currentStep &&
              typeof steps != "undefined" &&
              Array.isArray(currentStep) &&
              currentStep.length &&
              steps!.length > 1 &&
              currentStep[0] === steps!.length))
          ) {
            setSidebarOpen(false);
            return (
              <div>
                <Title order={2}>{t("components.thank-submitting")}</Title>
                <Divider my={20}></Divider>
        <Flex direction={"column"} justify={"center"} align={"center"}>

                Scarica il pdf della tua compilazione  
                <Group position="right" mb="xs"><AnswerPdf callId={sub?.callId!}></AnswerPdf></Group>
      </Flex>
              
                <Divider my={10}></Divider>
                <Link to="/calls">
                  <Button
                    variant="light"
                    radius="xl"
                    w={"100%"}
                    my={30}
                    leftIcon={<IconArrowBack size={14} />}
                  >
                    {t("components.go-back-calls")}
                  </Button>
                </Link>
                
              </div>
            );
          }

          const config = stepsConfig[steps![currentStep[0] ?? 0]?.stepType?.name!];
          const StepCmp =
            (config && config.FillableComponent) ||
            (() => (
              <Text>
                {t("components.not-implemented")}{" "}
                {steps![currentStep[0] ?? 0]?.stepType?.name!}
              </Text>
            ));

          return steps && (
            <StepCmp
              submission={submission}
              currentStepRef={currentStepRef}
              step={
                steps!.sort((a, b) => (+a.sort! > +b.sort! ? 1 : -1))[
                  currentStep[0] ?? 0
                ]
              }
              data={
                results?.find((sr) => sr.stepId === steps![currentStep[0] ?? 0]!.id)
                  ?.content
              }
              setData={(data, score = 0) => {
                if( sub?.closed && !!submissionId) return new Promise<StepResult>((resolve, reject) => {});
                return postApiCallUpsertCallStepResult({
                  callSubmissionId: submissionId,
                  stepId: steps![currentStep[0]].id,
                  content: data,
                  score,
                })
              }}
              readOnly={readOnly || (!!submissionId && sub && sub.closed)}
            />
          );
        })()}
      </Paper>
      {steps &&
        sub &&
        !sub.closed &&
        steps.length > 1 &&
        currentStep &&
        Array.isArray(currentStep) &&
        currentStep.length &&
        currentStep[0] != steps!.length && (
          <Flex justify={"space-between"}>
            <div>
              {(currentStep[0] > 0 || currentStep[1] > 0) && (
                <Button
                  onClick={previousStep}
                  variant="outline"
                  color="blue"
                  radius="xl"
                  leftIcon={<IconArrowLeft />}
                >
                  {t("components.previous")}
                </Button>
              )}
            </div>
            {sub && !sub.closed && (
              <div>
                {(currentStep &&
                  Array.isArray(currentStep) &&
                  currentStep.length &&
                  currentStep[0] < steps.length - 1) ||
                (!!storeSteps[currentStep[0]]?.subSteps &&
                  currentStep[1] <
                    storeSteps[currentStep[0]]?.subSteps?.length! - 1) ? (
                  <Button
                    onClick={() => nextStep(currentStepRef)}
                    variant="outline"
                    color="blue"
                    radius="xl"
                    rightIcon={<IconArrowRight />}
                  >
                    {t("components.next")}
                  </Button>
                ) : (
                  <Button
                    onClick={openConfirmationModal}
                    variant="outline"
                    color="blue"
                    radius="xl"
                    rightIcon={<IconArrowRight />}
                  >
                    {t("components.submit")}
                  </Button>
                )}
              </div>
            )}
          </Flex>
        )}
    </Flex>
  );
}

function Sidebar() {
  const { submissionId } = useParams();
  const { data: sub } = useGetApiCallGetSubmissionByID({
    submissionId,
  });
  const { steps, setCurrentStep, currentStep } = useStepperStore();
  const setSidebarContent = useStore(sidebarStore, (state) => state.setContent);
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {

  
    setSidebarContent(
      <div>
        {sub && !sub.closed && <Button
            variant="light"
            mb={15}
            leftIcon={<IconArrowBack size={18}/>}
            fullWidth
            onClick={() => navigate("/calls")}
        >
          {t("components.go-back-calls")}
        </Button>}

        <Box sx={{ width: 240 }}>
          {steps &&
            steps
              .sort((a, b) => (+a.sort! > +b.sort! ? 1 : -1))
              .map((step, stepIndex) => {
                return (
                  <>
                    <NavLink
                      onClick={() => setCurrentStep([stepIndex, 0])}
                      active={currentStep[0] == stepIndex}
                      style={{ float: "right" }}
                      label={step.name}
                      childrenOffset={12}
                      sx={(theme) => ({
                        borderLeft:
                          currentStep[0] == stepIndex
                            ? `2px solid ${theme.colors.blue[6]}`
                            : `2px solid ${theme.colors.blue[1]}`,
                      })}
                    >
                      {step.subSteps?.length! > 0 &&
                        step.subSteps?.map((substep, subStepIndex) => (
                          <NavLink
                            key={step.id}
                            onClick={() =>
                              setCurrentStep([stepIndex, subStepIndex])
                            }
                            active={
                              currentStep[0] == stepIndex &&
                              currentStep[1] == subStepIndex
                            }
                            color="blue"
                            style={{ float: "right" }}
                            label={substep.name}
                            sx={(theme) => ({
                              borderLeft:
                                currentStep[0] == stepIndex &&
                                currentStep[1] == subStepIndex
                                  ? `2px solid ${theme.colors.blue[6]}`
                                  : `2px solid ${theme.colors.blue[1]}`,
                            })}
                          ></NavLink>
                        ))}
                    </NavLink>
                  </>
                );
              })}
        </Box>
      </div>
    );
                
 }, [sidebarStore,currentStep,steps])
  return <></>;
}

export default { Stepper, Sidebar };
